import {
  Button,
  Center,
  Heading,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useMemo } from "react";

import Card from "./Card";

const RefTable = ({ test, currentQuestionIndex }) => {
  return (
    <TableContainer>
      <Center>
        <Heading fontSize="small">טבלה מספר 1</Heading>
      </Center>
      <Table variant="simple">
        <Thead>
          <Tr>
            {test.headers.map((value) => (
              <Th key={value}>{value}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr bg="green.200">
            {test.table[currentQuestionIndex].values.map((cell) => (
              <Td>{cell}</Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ActiveTable = ({ test, currentQuestionIndex }) => {
  return (
    <TableContainer>
      <Center>
        <Heading fontSize="small">טבלה מספר 2</Heading>
      </Center>
      <Table variant="simple">
        <Thead>
          <Tr>
            {test.headers.map((value) => (
              <Th key={value}>{value}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr bg="green.200">
            {test.questions[currentQuestionIndex].row.map((cell) => (
              <Td>{cell}</Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const QuestionCard = ({ test, currentQuestionIndex }) => {
  return (
    <Card>
      <Center minHeight="inherit" color="inherit">
        {test && (
          <VStack>
            <RefTable test={test} currentQuestionIndex={currentQuestionIndex} />
            <ActiveTable
              test={test}
              currentQuestionIndex={currentQuestionIndex}
            />
          </VStack>
        )}
      </Center>
    </Card>
  );
};

const OptionCard = ({ value, onClick, ...props }) => {
  return (
    <Card onClick={onClick} {...props}>
      <Center minHeight="inherit" color="inherit">
        <Text color="inherit">{value}</Text>
      </Center>
    </Card>
  );
};

const resolveStaticOptions = (test) => {
  return [...test.headers, "יותר מאחת"];
};

const KidodTestContent = ({
  test,
  selectedAnswer,
  handleAnswerSelect,
  handleSubmit,
  selectedMistake,
  currentQuestionIndex,
}) => {
  const options = useMemo(() => {
    return resolveStaticOptions(test);
  }, [test]);

  return (
    <VStack>
      <QuestionCard
        test={test}
        currentQuestionIndex={currentQuestionIndex}
        minHeight="128px"
        minWidth="365px"
        maxWidth="80vw"
      />
      <SimpleGrid width="100%" columns={2} spacing={2}>
        {options.map((option, i) => {
          const offsetI = i + 1;
          let bgColor = "white";
          if (offsetI === selectedAnswer) bgColor = "green.400";
          if (offsetI === selectedMistake) bgColor = "red.400";
          return (
            <OptionCard
              key={option}
              minHeight="64px"
              minWidth="64px"
              value={option}
              onClick={() => handleAnswerSelect(offsetI)}
              cursor="pointer"
              color={bgColor !== "white" ? "white" : "text"}
              background={bgColor}
              __hover={{
                background: bgColor === "white" ? "green.600" : bgColor,
              }}
            />
          );
        })}
      </SimpleGrid>
      {handleSubmit && (
        <Button
          width="100%"
          disabled={selectedAnswer === -1}
          onClick={handleSubmit}
        >
          המשך
        </Button>
      )}
    </VStack>
  );
};

export default KidodTestContent;
