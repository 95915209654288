import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { isUserLicenseValid } from "../../../model/user";

const LockedModal = ({ isUserPayed, onEnterSerial, onBuyApp, onClose }) => {
  useEffect(() => {
    if (isUserPayed) onClose();
  }, [isUserPayed]);

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>שימוש באפליקציה</Heading>
        </ModalHeader>
        <ModalBody>
          <Text>על מנת להמשיך יש לרכוש רישיון לגרסה המלאה</Text>
        </ModalBody>
        <ModalFooter gap="8px">
          <Button colorScheme="green" onClick={onBuyApp}>
            רכישת רישיון
          </Button>
          <Button colorScheme="gray" onClick={onEnterSerial}>
            הזנת קוד
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LockedModal;
