import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: "VarelaRound";
        src: local("Varela Round"), url("./fonts/varelaround.ttf") format("truetype");
      }   
      @font-face {
        font-family: "Quicksand";
        src: local("Quicksand"), url("./fonts/quicksand.ttf") format("truetype");
      }  
    `}
  />
);

export default Fonts;
