import { secondsInMinute } from "date-fns";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";

export const TestTypes = {
  Regular: "regular",
  Eranot: "eranot",
  ZihoyKidod: "zihoy-kidod",
};

/*
Test
{
    id: string,
    name: string,
    type: string,
    order: number,
    questions: Question[],
    lengthInSeconds: number,
    enabled: boolean
}
Question
{
    question: string 
    isTextual: boolean // indicates if the question field is an image or a text
    options: string[]
}
*/

/*
TestResult
{
    id: string
    userId: string
    test: Test
    score: number
    answers: number[]
    finishTimeInSeconds: number
}
*/

export const getFormattedTestQuestionAmount = (test) => {
  if (test.type !== "regular") return "";
  const questions = test?.questions.length;
  return `${questions} שאלות`;
};

export const getFormattedTestLength = (test) => {
  const sec = test.lengthInSeconds % secondsInMinute;
  const min = Math.round((test.lengthInSeconds - sec) / secondsInMinute);
  const minText = min === 1 ? "דקה" : "דקות";
  const secText = sec === 1 ? "שניה" : "שניות";

  if (sec === 0) return `${min} ${minText}`;

  return `${min} ${minText}, ${sec} ${secText}`;
};

export const calculateTestScore = (test, answers) => {
  let score = 100;

  let rightAnswers = 0;
  answers.forEach((answer, i) => {
    if (test.questions[i].answer === answer) rightAnswers++;
  });

  if (!test.questions) throw new Error("no questions provided");

  const answerWorth =
    (100 / test.questions.length) * (test?.modifiers?.wrongAnswerValue ?? 1);

  const missedAnswers = test.questions.length - rightAnswers;
  const finalScore = Math.max(
    score - Math.round(missedAnswers * answerWorth),
    0
  );
  return [finalScore, rightAnswers];
};

export const saveTestResults = async (
  userId,
  test,
  finishTimeInSeconds,
  score,
  answers,
  metadata = {}
) => {
  const id = uuid();
  await setDoc(doc(getFirestore(), `test-results`, id), {
    id,
    userId,
    test,
    score,
    answers: answers ?? [],
    finishTimeInSeconds,
    testLengthInSeconds: test.lengthInSeconds,
    createdAt: Date.now(),
    metadata,
  });
  return id;
};
