import { Box, Container, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router";
import Background from "./Background";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = () => {
  return (
    <Box height="100vh">
      <Background />
      <VStack minHeight="100vh" position="relative">
        <Navbar />
        <Outlet />
        <Box height="128px" />
        <Footer />
      </VStack>
    </Box>
  );
};

export default Layout;
