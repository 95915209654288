import {
  Center,
  Divider,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { Container, Row } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router";
import Card from "../../components/Card";
import EmptyView from "../../components/EmptyView/EmptyView";
import ErrorView from "../../components/ErrorView";
import LoadingScreen from "../../components/LoadingScreen";
import { useCurrentUser } from "../../hooks/auth";

const useTestResults = (userId) => {
  return useCollectionData(
    query(
      collection(getFirestore(), `test-results`),
      where("userId", "==", userId)
    )
  );
};

const TestReviewAll = () => {
  const user = useCurrentUser();
  const [values, loading, error] = useTestResults(user?.uid ?? "");
  const navigate = useNavigate();

  if (error) {
    return (
      <Container>
        <Center>
          <ErrorView error={error} />
        </Center>
      </Container>
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if(values.length === 0) {
    return (
      <EmptyView
        title="לא נמצאו מבחנים שהסתיימו"
        content=".עד שיופיעו כמה אנחנו חוזרים לנמנם"
        action={() => navigate('/')}
        actionText="למסך הבית"
      />
    )
  }

  return (
    <Container>
      <Row>
        <Stack spacing="8px" textAlign="start" marginTop="24px">
          <Heading fontSize="x-large">מבחנים שהסתיימו</Heading>
          <Divider color="yellow.400" w="128px" />
        </Stack>
      </Row>
      <Row style={{ marginTop: "16px" }}>
          <SimpleGrid spacing="8px" minChildWidth="256px">
            {values
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((result) => (
                <Card
                  key={result.id}
                  bg="white"
                  cursor="pointer"
                  onClick={() => navigate(`/review/${result.id}`)}
                >
                  <VStack width="100%" align="start">
                    <Heading fontSize="large">{result.test.name}</Heading>
                    <Divider width="80%" color="yellow.400" />
                    <Text>{format(result.createdAt, "dd/MM/yyyy HH:mm")}</Text>
                    <Wrap>
                      <Text>ציון: {result.score}</Text>
                      <Text>
                        זמן:{" "}
                        {format(result.finishTimeInSeconds * 1000, "mm:ss")}
                      </Text>
                    </Wrap>
                  </VStack>
                </Card>
              ))}
          </SimpleGrid>
      </Row>
    </Container>
  );
};

export default TestReviewAll;
