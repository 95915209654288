export const isURL = (str) => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * max) + min;
};
