import { Heading, VStack,Text, Button, AbsoluteCenter } from "@chakra-ui/react"
import { ReactComponent as BearIcon } from './bear-icon.svg'

const EmptyView = ({ title, content, action, actionText }) => {
    return <AbsoluteCenter>
        <VStack>
        <Heading>{title}</Heading>
        <Text>{content}</Text>
        <BearIcon height="256px" width="256px"/>
        {action && actionText && <Button onClick={action}>{actionText}</Button>}
    </VStack>
    </AbsoluteCenter>
}

export default EmptyView