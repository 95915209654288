import {
  AspectRatio,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import isWithingInterval from "date-fns/isWithinInterval";
import ms from "ms";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Card from "../../components/Card";
import ErrorView from "../../components/ErrorView";
import LoadingScreen from "../../components/LoadingScreen";
import TestDescription from "../../components/TestDescription";
import { useCurrentUser } from "../../hooks/auth";
import { saveTestResults } from "../../model/test";
import { getRandomInt } from "../../util";
import { useTestClock, useTestData } from "./hooks";

const nextValue = (value) => {
  if (value === " ") {
    switch (getRandomInt(0, 2)) {
      case 0:
        return "1";
      case 1:
        return "2";
    }
  } else {
    return " ";
  }
};

const newValue = () => {
  const int = getRandomInt(0, 3);
  switch (int) {
    default:
      return " ";
    case 1:
      return "1";
    case 2:
      return "2";
  }
};

const generateInitialValues = () => {
  return Array(25).fill(" ").map(newValue);
};

export const calculateTestScore = (changes, clicks) => {

  const clicksCopy = [...clicks]

  let accuracy = 0;
  changes.forEach((change) => {
    const index = clicksCopy.findIndex(
      (click) =>
        change.timestamp <= click.timestamp &&
        click.timestamp <= change.timestamp + ms("3s")
    );

    if (index === -1) {
      // didn't click or clicked the wrong value
      return;
    }

    const click = clicksCopy[index];
    clicksCopy.splice(index, 1); // remove used clicks

    if (click.answer !== change.value) {
      return;
    }

    // good click
    accuracy += 1;
  });

  const clampedAccuracy = Math.max(accuracy, 0);
  return [Math.round((clampedAccuracy / changes.length) * 100), clampedAccuracy];
};

const useAttentionTest = (user, test) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [ended, setEnded] = useState(false);
  const [values, setValues] = useState(generateInitialValues());
  const [changes, setChanges] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);

  const { testStarted, startTest, timeLeft, didTimeout, timeItTookInSeconds } =
    useTestClock(test);

  useEffect(() => {
    if (!testStarted || ended) return;
    const id = window.setInterval(() => {
      const changeIndex = getRandomInt(0, values.length);

      const oldValue = values[changeIndex];
      const newValue = nextValue(oldValue);

      const newValues = [
        ...values.slice(0, changeIndex),
        newValue,
        ...values.slice(changeIndex + 1),
      ];

      setChanges([
        ...changes,
        {
          timestamp: Date.now(),
          value: newValue === " " ? oldValue : newValue,
        },
      ]);
      setValues(newValues);
    }, ms("3s"));

    return () => {
      window.clearInterval(id);
    };
  }, [testStarted, values, ended]);

  const handleTestEnd = async () => {
    if (ended) return;
    setEnded(true);

    try {
      const [score, accuracy] = calculateTestScore(changes, clicks);
      const id = await saveTestResults(
        user.uid,
        test,
        timeItTookInSeconds,
        score,
        clicks,
        {
          changes,
          accuracy,
        }
      );
      toast({
        title: "סיום מבחן",
        description: "המבחן הסתיים בהצלחה",
        status: "success",
        isClosable: true,
      });
      navigate(`/review/${id}`, { replace: true });
    } catch (error) {
      console.error(error);
      toast({
        title: "סיום מבחן",
        description: "הייתה בעיה בשמירת המבחן",
        status: "error",
        isClosable: true,
      });
      setEnded(false);
    }
  };

  useEffect(() => {
    if (didTimeout && !ended) handleTestEnd();
  }, [didTimeout, ended]);

  const handleAnswerSelect = (answer) => {
    if (ended) return;

    setTotalClicks(totalClicks + 1);
    setClicks([...clicks, { answer, timestamp: Date.now() }]);
  };

  return {
    testName: test?.name,
    testStarted,
    startTest,
    timeLeft,
    handleAnswerSelect,
    values,
    totalClicks,
  };
};

const AttentionTest = () => {
  const { id } = useParams();
  const user = useCurrentUser();
  const [test, loading, error] = useTestData(id);

  const {
    testName,
    startTest,
    testStarted,
    timeLeft,
    values,
    totalClicks,
    handleAnswerSelect,
  } = useAttentionTest(user, test);

  if (error) {
    return (
      <Container>
        <Center>
          <ErrorView error={error} />
        </Center>
      </Container>
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  const content = () => {
    if (!testStarted) {
      return (
        <TestDescription
          onStart={startTest}
          test={test}
          actionText="התחל מבחן"
        />
      );
    }

    return (
      <VStack>
        <Card width="80vw" height="80vw" maxWidth="512px" maxHeight="512px">
          <SimpleGrid width="100%" height="100%" columns={5}>
            {values.map((value, i) => (
              <Center key={i}>
                <Text>{value}</Text>
              </Center>
            ))}
          </SimpleGrid>
        </Card>
        <VStack width="100%">
          <Flex gap="16px" width="100%">
            <Button flex={1} onClick={() => handleAnswerSelect("2")}>2</Button>
            <Button flex={1} onClick={() => handleAnswerSelect("1")}>1</Button>
          </Flex>
          <Text>לחיצות שהתקבלו: {totalClicks}</Text>
        </VStack>
      </VStack>
    );
  };

  return (
    <Container>
      <Row style={{ marginTop: "16px" }}>
        <Center>
          <VStack>
            <Heading as="h3" fontSize="x-large">
              {testName}
            </Heading>
            {testStarted && <Text>{timeLeft}</Text>}
          </VStack>
        </Center>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Center>{content()}</Center>
      </Row>
    </Container>
  );
};

export default AttentionTest;
