import { AbsoluteCenter, Spinner } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router";
import { useCurrentUser } from "../hooks/auth";
import LoadingScreen from "./LoadingScreen";

const Protected = ({ children }) => {
  const [user, loading] = useAuthState(getAuth());

  if (loading) {
    return <LoadingScreen />;
  }

  if (user === null) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default Protected;
