import {
  SimpleGrid,
  Button,
  Wrap,
  Center,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { getFirestore, collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import LoadingScreen from "../../../components/LoadingScreen";
import LearningResourceCard from "./LearningResourceCard";

const useLearningResources = () => {
  return useCollectionData(collection(getFirestore(), "learning-resources"));
};

const LearningResourcesGrid = () => {
  const [resources, loading, error] = useLearningResources();

  if (error) {
    return <Text>התרחשה שגיאה בלתי צפויה</Text>;
  }

  if (loading) {
    return (
      <Center width="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <SimpleGrid minChildWidth="256px" spacing="8px" width="100%">
      {resources.map((resource) => {
        return <LearningResourceCard key={resource.id} resource={resource} />;
      })}
    </SimpleGrid>
  );
};

export default LearningResourcesGrid;
