import { Button, Center, Heading, Text, VStack } from "@chakra-ui/react";
import Card from "./Card";

const TestDescription = ({ test, onStart, actionText }) => {
  return (
    <VStack width="512px">
      <Card minHeight="256px" overflowY="scroll">
        <Center>
          <VStack>
            <Heading marginBottom="4px" color="text" fontSize="medium">
              הסבר
            </Heading>
            {test.instructions.split("~").map((line) => (
              <Text marginTop="0px !important" textAlign="center">{line}</Text>
            ))}
          </VStack>
        </Center>
      </Card>
      <Button width="100%" onClick={onStart}>
        {actionText}
      </Button>
    </VStack>
  );
};

export default TestDescription;
