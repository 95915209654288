import {
  Center,
  Divider,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { Container, Row } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router";
import Card from "../../components/Card";
import ErrorView from "../../components/ErrorView";
import LoadingScreen from "../../components/LoadingScreen";
import { useCurrentUser } from "../../hooks/auth";
import groupBy from "lodash/groupBy";

const useTestResults = (userId) => {
  return useCollectionData(
    query(
      collection(getFirestore(), `test-results`),
      where("userId", "==", userId)
    )
  );
};

const inRange = (value, start, end) => {
  return start <= value && value <= end;
};

const mapScoreToGrade = (score) => {
  switch (true) {
    case inRange(score, 0, 20):
      return 1;
    case inRange(score, 21, 40):
      return 2;
    case inRange(score, 41, 60):
      return 3;
    case inRange(score, 61, 80):
      return 4;
    case inRange(score, 81, 100):
      return 5;
    default:
      return null;
  }
};

const useGrades = (testResults) => {
  const testResultsWithScore = testResults.filter(res => res.score && !isNaN(res.score))
  const testsByCategory = groupBy(testResultsWithScore, (res) => res.test.category);

  const gradesMap = new Map();

  for (const category of Object.keys(testsByCategory)) {
    const value = testsByCategory[category];
    const totalScore = value.reduce((agg, curr) => agg + curr.score, 0);

    const avg = Math.round(totalScore / value.length);

    gradesMap.set(category, mapScoreToGrade(avg));
  }

  const grades = [
    {
      category: "הפעלה טכנית",
      name: "הפעלה טכנית",
      subtitle: "מתייחס למבחן מערכת ומבחן טבלאות",
    },
    {
      category: "תפיסת מרחב",
      name: "תפיסת מרחב",
      subtitle: "מתייחס למבחן התמצאות במרחב ומבחן רוטציות",
    },
    {
      category: "ניהול וארגון",
      name: "ניהול וארגון",
      subtitle: "מתייחס למבחן זיהוי קידוד",
    },
    {
      category: "עיבוד מידע",
      name: "עיבוד מידע/גמישות מחשבתית",
      subtitle: "מתייחס למבחן עיבוד מידע",
    },
    {
      category: "יכולת טכנית",
      name: "יכולת טכנית",
      subtitle: "מתייחס למבחן סינון קבצים",
    },
    {
      category: "קשב מתמשך",
      name: "קשב מתמשך",
      subtitle: "מתייחס למבחן ערנות",
    },
    {
      cateogry: "תגובתיות",
      name: "תגובתיות",
      subtitle: "מתייחס למבחן זיהוי פריט",
    },
  ].map((item) => {
    return {
      ...item,
      score: gradesMap.has(item.category) ? gradesMap.get(item.category) : null,
    };
  });

  return {
    grades,
  };
};

const Grades = () => {
  const user = useCurrentUser();
  const [values = [], loading, error] = useTestResults(user?.uid ?? "");

  const { grades } = useGrades(values);

  if (error) {
    return (
      <Container>
        <Center>
          <ErrorView error={error} />
        </Center>
      </Container>
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <Row>
        <Stack spacing="8px" textAlign="start" marginTop="24px">
          <Heading fontSize="x-large">ציונים</Heading>
          <Text>
            על מנת לראות את הציונים הרלוונטיים יש לסיים לפחות מבחן אחד מאותו סוג
          </Text>
          <Divider color="yellow.400" w="128px" />
        </Stack>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <SimpleGrid spacing="8px" minChildWidth="256px">
          {grades.map((grade) => (
            <Card bg="white">
              <VStack width="100%" align="start">
                <Heading fontSize="large">{grade.name}</Heading>
                <Text height="48px">{grade.subtitle}</Text>
                <Divider width="80%" color="yellow.400" />
                <Text fontSize="xx-large">
                  5/{grade.score === null ? "?" : grade.score}
                </Text>
              </VStack>
            </Card>
          ))}
        </SimpleGrid>
      </Row>
    </Container>
  );
};

export default Grades;
