import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./screens/Login/Login";
import Home from "./screens/Home/Home";
import Test from "./screens/Test/Test";
import Protected from "./components/Protected";
import Fonts from "./fonts";
import theme from "./theme";
import TestReviewSpecific from "./screens/TestReview/TestReviewSpecific";
import TestReviewAll from "./screens/TestReview/TestReviewAll";
import AttentionTest from "./screens/Test/AttentionTest";
import LicenseProtected from "./components/LicenseProtected";
import Grades from "./screens/Grades/Grades";
import Reset from "./screens/Reset/Reset";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <Protected>
                  <Home />
                </Protected>
              }
            />
            <Route
              path="grades"
              element={
                <Protected>
                  <Grades />
                </Protected>
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="test">
              <Route
                path="attention/:id"
                element={
                  <Protected>
                    <LicenseProtected>
                      <AttentionTest />
                    </LicenseProtected>
                  </Protected>
                }
              />
              <Route
                path=":id"
                element={
                  <Protected>
                    <LicenseProtected>
                      <Test />
                    </LicenseProtected>
                  </Protected>
                }
              />
            </Route>
            <Route path="review">
              <Route
                path="all"
                element={
                  <Protected>
                    <TestReviewAll />
                  </Protected>
                }
              />
              <Route
                path=":id"
                element={
                  <Protected>
                    <TestReviewSpecific />
                  </Protected>
                }
              />
            </Route>
            <Route
              path="reset"
              element={
                <Protected>
                  <Reset />
                </Protected>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
