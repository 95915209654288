import {
  Button,
  Center,
  Divider,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import Card from "../../components/Card";
import ErrorView from "../../components/ErrorView";
import KidodTestContent from "../../components/KidodTestContent";
import LoadingScreen from "../../components/LoadingScreen";
import TestContent from "../../components/TestContent";

export const useTestResultData = (testResultId) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDoc(doc(getFirestore(), `test-results`, testResultId))
      .then((snap) => {
        setData(snap.data());
        if (loading) setLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  return [data, loading, error];
};

const TestReviewSpecific = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // get test result id
  const [data, loading, error] = useTestResultData(id);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

  if (error) {
    return (
      <Container>
        <Center>
          <ErrorView error={error} />
        </Center>
      </Container>
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  const resolveContent = () => {
    if (selectedQuestionIndex !== null) {
      const question = data.test.questions[selectedQuestionIndex];

      let selectedMistake = undefined;
      if (data.answers[selectedQuestionIndex] !== question.answer) {
        selectedMistake = data.answers[selectedQuestionIndex];
      }

      return (
        <VStack justify="center">
          {data.test.type === "kidod" ? (
            <KidodTestContent
              test={data.test}
              currentQuestionIndex={selectedQuestionIndex}
              selectedAnswer={question.answer}
              selectedMistake={selectedMistake}
            />
          ) : (
            <TestContent
              {...question}
              explanation={question.explanation}
              selectedAnswer={question.answer}
              selectedMistake={selectedMistake}
            />
          )}
          <Button onClick={() => setSelectedQuestionIndex(null)}>חזור</Button>
        </VStack>
      );
    }

    return (
      <SimpleGrid gap="8px" minChildWidth="64px" maxWidth="60vw">
        {data.test.questions.map((question, i) => {
          let bgColor = "white";
          if (data.answers.length > i) {
            if (question.answer === data.answers[i]) {
              bgColor = "green.400";
            } else {
              bgColor = "red.400";
            }
          }
          return (
            <Card
              key={question.question}
              background={bgColor}
              onClick={() => setSelectedQuestionIndex(i)}
              cursor="pointer"
            >
              <Center>
                <Text color={bgColor === "white" ? "text" : "white"}>
                  {i + 1}
                </Text>
              </Center>
            </Card>
          );
        })}
      </SimpleGrid>
    );
  };

  return (
    <Container>
      <Row style={{ marginTop: "16px" }}>
        <Center>
          <VStack>
            <Heading as="h3" fontSize="x-large">
              סיום מבחן
            </Heading>
            <Heading as="h4" fontSize="large">
              {data.test.name}
            </Heading>
            <Divider />
            {selectedQuestionIndex === null && (
              <>
                {!isNaN(data.score) && <Text>ציון: {data.score}</Text>}
                <Text>
                  תשובות נכונות: {data?.metadata?.accuracy ?? 0}/
                  {data.test?.questions?.length ??
                    data?.metadata?.changes?.length ??
                    "?"}
                </Text>
                <Text>
                  זמן מבחן: {format(data.finishTimeInSeconds * 1000, "mm:ss")}
                </Text>
              </>
            )}
          </VStack>
        </Center>
      </Row>
      {["regular", "kidod"].includes(data.test.type) && (
        <Row style={{ marginTop: "32px" }}>
          <Center>{resolveContent()}</Center>
        </Row>
      )}
      {selectedQuestionIndex === null && (
        <Row>
          <Center>
            <Button marginTop="16px" onClick={() => navigate(-1)}>
              חזור
            </Button>
          </Center>
        </Row>
      )}
    </Container>
  );
};

export default TestReviewSpecific;
