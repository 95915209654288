import { isBefore } from "date-fns";
import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const useCurrentUser = () => {
  const auth = getAuth();
  const [user, setUser] = useState(auth.currentUser);

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, [auth, setUser]);

  return user;
};
