import { Divider, Heading, Stack, VStack } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { createUseStyles } from "react-jss";
import { useCurrentUser } from "../../hooks/auth";
import { useIsUserPayed } from "../../model/user";
import ActionsGrid from "./ActionsGrid";
import LearningResourcesGrid from "./LearningResourcesGrid";
import SerialInputModal from "./SerialInputModal";
import TestsGrid from "./TestsGrid";

const WithTitle = ({ title, children }) => {
  return (
    <VStack align="start" gap="12px" width="100%">
      <Heading fontSize="x-large" color="text">
        {title}
      </Heading>
      {children}
    </VStack>
  );
};

const useHome = () => {
  const { currentUser } = getAuth();

  const name = currentUser?.displayName || currentUser?.email || "";

  return {
    header: currentUser === null ? "ברוך הבא" : "שלום, " + name,
  };
};

const useHomeStyle = createUseStyles({
  container: { display: "flex", gap: "24px", flexDirection: "column" },
});

const Home = () => {
  const style = useHomeStyle();
  const user = useCurrentUser();
  const { header } = useHome();

  const isUserPayed = useIsUserPayed();

  const [showSerialModal, setShowSerialModal] = useState(false);

  const onBuyApp = () => {
    window.open(
      `https://info.tzav-rishon.co.il/%D7%9E%D7%A1%D7%9A-%D7%A8%D7%9B%D7%99%D7%A9%D7%94-%D7%90%D7%A4%D7%9C%D7%99%D7%A7%D7%A6%D7%99%D7%99%D7%AA-%D7%99%D7%95%D7%9D-%D7%94%D7%9E%D7%90%D7%94/`,
      "__blank"
    );
  };

  const onActivateSerial = () => {
    setShowSerialModal(true);
  };

  return (
    <Container className={style.container}>
      <Row>
        <Stack spacing="8px" textAlign="start" marginTop="24px">
          <Heading fontSize="x-large">{header}</Heading>
          <Divider color="yellow.400" w="128px" />
        </Stack>
      </Row>
      <Row>
        <WithTitle title="פעולות משתמש">
          <ActionsGrid
            isUserPayed={isUserPayed}
            onBuyApp={onBuyApp}
            onActivateSerial={onActivateSerial}
          />
        </WithTitle>
      </Row>
      <Row>
        <WithTitle title="משאבי למידה">
          <LearningResourcesGrid />
        </WithTitle>
      </Row>
      <Row style={{ paddingBottom: "16px" }}>
        <WithTitle title="מבחנים">
          <TestsGrid
            user={user}
            isUserPayed={isUserPayed}
            onBuyApp={onBuyApp}
            onActivateSerial={onActivateSerial}
          />
        </WithTitle>
      </Row>
      {showSerialModal && (
        <SerialInputModal
          user={user}
          onClose={() => setShowSerialModal(false)}
        />
      )}
    </Container>
  );
};

export default Home;
