import { Button, Center, Heading, Text, VStack } from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";

const ErrorView = ({ error }) => {
  const navigate = useNavigate();

  return (
    <Center>
      <VStack>
        <Heading>האפליקציה נתקלה בבעיה</Heading>
        <Text>אנא נסה שנית מאוחר יותר</Text>
        <Text>שגיאה: {error}</Text>
        <Button onClick={() => navigate("/")}>חזרה למסך הבית</Button>
      </VStack>
    </Center>
  );
};

export default ErrorView;
