import { AbsoluteCenter, Spinner } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router";
import { useIsUserPayed } from "../model/user";

const LicenseProtected = ({ children }) => {
  const isPayed = useIsUserPayed();

  if (isPayed === null) {
    return null;
  }

  if (!isPayed) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default LicenseProtected;
