import { Box } from "@chakra-ui/react";

const Card = ({ children, ...props }) => {
  return (
    <Box
      bg="white"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;"
      borderRadius="8px"
      padding="12px 18px"
      {...props}
    >
      {children}
    </Box>
  );
};

export default Card;
