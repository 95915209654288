import {
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { activateSerial } from "../../model/serial";

const SerialInputModal = ({ user, onClose }) => {
  const toast = useToast();
  const [serial, setSerial] = useState("");

  const onActivate = async () => {
    try {
      await activateSerial(user.uid, serial);
      toast({
        title: "הפעלת גרסה",
        description: "גרסה הופעלה בהצלחה",
      });
      onClose();
    } catch (error) {
      toast({
        title: "הפעלת גרסה",
        status: "error",
        description: error.message,
      });
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>הפעלת אפליקציה</Heading>
        </ModalHeader>
        <ModalBody>
          <Text>על מנת להמשיך יש להזין רישיון לאפליקציה</Text>
          <Input
            placeholder="סריאל בתוקף"
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
          />
        </ModalBody>
        <ModalFooter gap="8px">
          <Button
            colorScheme="green"
            disabled={serial.length < 5}
            onClick={onActivate}
          >
            הפעלת רישיון
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SerialInputModal;
