import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../hooks/auth";

const Footer = () => {
  const user = useCurrentUser();
  const location = useLocation();

  if (location.pathname === "/login") return null;

  return (
    <Container
      style={{
        position: "absolute",
        bottom: 0,
        paddingTop: "8px",
        paddingBottom: "16px",
      }}
    >
      <SimpleGrid
        align="center"
        justify="space-between"
        columns={3}
        flexWrap="wrap"
        width="100%"
        gap="4px"
      >
        <Text color="text" fontSize="small">
          להודעות בוואטסאפ: 050-3603444
        </Text>
        <Text
          as="a"
          color="text"
          fontSize="small"
          href="https://info.tzav-rishon.co.il/%d7%aa%d7%a0%d7%90%d7%99-%d7%a9%d7%99%d7%9e%d7%95%d7%a9-%d7%95%d7%a4%d7%a8%d7%98%d7%99%d7%95%d7%aa-%d7%a6%d7%95-%d7%a8%d7%90%d7%a9%d7%95%d7%9f/"
        >
          תנאי שימוש ומדיניות הפרטיות
        </Text>
        {user !== null && (
          <Link to="/reset">
            <Text as="a" color="text" fontSize="small">
              מחק מידע משתמש
            </Text>
          </Link>
        )}
      </SimpleGrid>
      <Box position="absolute" right={0} left={0}>
        <Text textAlign="center" color="text" fontSize="xx-small">
          COPYRIGHT © 2022 TZAV RISHON
        </Text>
      </Box>
    </Container>
  );
};

export default Footer;
