import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
} from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import format from "date-fns/format";
import ms from "ms";

export const useTestData = (testId) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDoc(doc(getFirestore(), `tests`, testId))
      .then((snap) => {
        setData(snap.data());
        if (loading) setLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  return [data, loading, error];
};

const useNow = () => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const id = window.setInterval(() => {
      setNow(Date.now());
    }, ms("1s"));

    return () => {
      window.clearInterval(id);
    };
  }, []);

  return now;
};

export const useTestClock = (test) => {
  const now = useNow();
  const [testStartTS, setTestStartTS] = useState(-1);
  const [didTimeout, setDidTimeout] = useState(false);
  const [testStarted, setTestStarted] = useState(false);

  // start counter when test is loaded
  useEffect(() => {
    if (test !== undefined && testStartTS === -1) setTestStartTS(Date.now());
  }, [test, testStartTS, setTestStartTS]);

  // end test if were passed length
  useEffect(() => {
    if (test === undefined || testStartTS === -1) return;
    // calculate time passed
    const timeDeltaSeconds = Math.round((now - testStartTS) / 1000);

    // end test if we are passed the defined length
    if (timeDeltaSeconds >= test.lengthInSeconds && !didTimeout)
      setDidTimeout(true);
  }, [now, testStartTS, test]);

  const timeLeft = useMemo(() => {
    if (test === undefined) return 0;
    const lengthMS = test.lengthInSeconds * 1000;
    const timeLeftMS = Math.max(lengthMS - (now - testStartTS), 0);
    return `זמן שנשאר : ${format(timeLeftMS, "mm:ss")}`;
  }, [now, testStartTS, test]);

  const timeItTookInSeconds = useMemo(() => {
    return (now - testStartTS) / 1000;
  }, [now, testStartTS]);

  return {
    didTimeout,
    testStarted,
    startTest() {
      setTestStarted(true);
    },
    timeLeft,
    timeItTookInSeconds,
  };
};
