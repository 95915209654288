import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const DesktopOnlyModal = ({ onClose }) => {
  return (
    <Modal size="md" isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>תוכן אינו נתמך במובייל</Heading>
        </ModalHeader>
        <ModalBody>
          <Text textAlign="center" paddingBottom="16px">
            התוכן של מבחן זה אינו נתמך בפלאפונים ניידים ועל כן יש לבצעו על
            טאבלטים או מחשבים ניידים/נייחים
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DesktopOnlyModal;
