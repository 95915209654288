import { Center, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router";
import { useIsMobile } from "../../../hooks/window";
import DesktopOnlyModal from "./DesktopOnlyModal";
import LockedModal from "./LockedModal";
import TestCard from "./TestCard";

const useTestsGrid = () => {
  const [values, loading, error] = useCollectionData(
    query(collection(getFirestore(), "tests"), where("enabled", "==", true))
  );

  return {
    tests: values,
    loading,
    error,
  };
};

const TestsGrid = ({ isUserPayed, onBuyApp, onActivateSerial }) => {
  const isMobile = useIsMobile();
  const { tests, loading, error } = useTestsGrid();
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [showDesktopOnlyModal, setShowDesktopOnlyModal] = useState(false);
  const navigate = useNavigate();

  if (error) {
    return <Text>התרחשה שגיאה בלתי צפויה</Text>;
  }

  if (loading) {
    return (
      <Center width="100%">
        <Spinner />
      </Center>
    );
  }

  const handleTestCardClick = (test) => {
    if (isUserPayed === null) return;

    if (!isUserPayed) {
      setShowLockedModal(true);
      return;
    }

    const desktopOnly = !(test?.mobile ?? true);
    if (desktopOnly && isMobile) {
      setShowDesktopOnlyModal(true);
      return;
    }

    if (test.type === "attention") {
      navigate(`/test/attention/${test.id}`);
      return;
    }
    navigate(`/test/${test.id}`);
  };

  return (
    <>
      <SimpleGrid minChildWidth="256px" spacing="8px" width="100%">
        {tests
          .sort((a, b) => a.order - b.order)
          .map((test) => {
            const desktopOnly = !(test?.mobile ?? true);
            return (
              <TestCard
                disabled={desktopOnly && isMobile}
                key={test.id}
                test={test}
                onClick={() => handleTestCardClick(test)}
              />
            );
          })}
      </SimpleGrid>
      {showDesktopOnlyModal && (
        <DesktopOnlyModal onClose={() => setShowDesktopOnlyModal(false)} />
      )}
      {showLockedModal && (
        <LockedModal
          isUserPayed={isUserPayed}
          onBuyApp={onBuyApp}
          onEnterSerial={onActivateSerial}
          onClose={() => setShowLockedModal(false)}
        />
      )}
    </>
  );
};

export default TestsGrid;
