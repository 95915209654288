import { Box, Center, Spinner } from "@chakra-ui/react";

const LoadingScreen = () => {
  return (
    <Box height="100vh" width="100vw">
      <Center height="inherit">
        <Spinner />
      </Center>
    </Box>
  );
};

export default LoadingScreen;
