import {
  Box,
  Button,
  Center, FormControl,
  FormLabel, Heading,
  Input, Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import {
  getAuth,
  signInWithEmailAndPassword, signInWithPopup, signInWithRedirect
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

import { useIsDesktop } from '../../hooks/window';

import { ReactComponent as AppleIcon } from "./apple.svg";
import { ReactComponent as GoogleIcon } from "./google.svg";

import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

googleProvider.setDefaultLanguage('he')
appleProvider.setDefaultLanguage('he')

const useStyles = createUseStyles({
  form: {
    padding: "1.5rem",
    marginTop: "8px",
    minWidth: "30vw",
    "@media screen and (max-width: 500px)": {
      minWidth: "unset",
      width: "100%",
    },
  },
  root: {
    position: "absolute",
    left: "0px",
    bottom: "0x",
    right: "0px",
    top: "0px",
    height: "100vh",
  },
  submit: {
    width: "100%",
  },
  icon: {
    padding: "1.2rem",
    marginLeft: "12px",
  },
});

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const toast = useToast();
  const isDesktop = useIsDesktop()

  const style = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [user] = useAuthState(getAuth());
  useEffect(() => {
    if (user !== null) navigate("/");
  }, [user, navigate]);

  useEffect(() => {
    auth.useDeviceLanguage();
  }, [auth]);

  const inputsValid = validateEmail(email) && password.length >= 6;

  const onSubmit = useCallback(async () => {
    if (!inputsValid) return;
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch ({ code = "", message = "" }) {
      switch (code) {
        case "auth/wrong-password":
          alert(code);
          break;
        default:
          alert(`${code} ${message}`);
          break;
      }
    }
  }, [auth, email, password, inputsValid]);

  useEffect(() => {
    const handler = (e) => {
      if (e.key === "Enter") onSubmit();
    };

    window.addEventListener("keypress", handler);
    return () => {
      window.removeEventListener("keypress", handler);
    };
  });

  const onAppleLogin = async (e) => {

    try {
      if(!isDesktop) await signInWithRedirect(auth, appleProvider)
      else await signInWithPopup(auth, appleProvider)
    } catch (error) {
      toast({
        title: "התחברות",
        description: `הייתה בעיה בהתחברות המשתמש: ${error.message}`,
        status: "error",
      });
    } 

    e.preventDefault();
  };

  const onGoogleLogin = async (e) => {
 
    try {
      if(!isDesktop) await signInWithRedirect(auth, googleProvider)
      else await signInWithPopup(auth, googleProvider)
    } catch (error) {
      toast({
        title: "התחברות",
        description: `הייתה בעיה בהתחברות המשתמש: ${error.message}`,
        status: "error",
      });
    } 

    e.preventDefault();
  };

  const showUsernamePasswordLogin = () => {
    return (
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("127.0.0.1")
    );
  };

  return (
    <Box as="main" bg="primary" className={style.root}>
      <Box
        as="form"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        height="100%"
        paddingBottom="32px"
      >
        <Heading fontSize="7xl">יום המאה</Heading>
        <Text marginTop={0} fontSize="x-large" fontWeight="light">
          התחבר למשתמש שלך
        </Text>
        <Box width={"100%"} maxWidth={312} dir="rtl">
          {showUsernamePasswordLogin() && (
            <>
              <FormControl marginTop={16}>
                <FormLabel htmlFor="email">כתובת אימייל</FormLabel>
                <Input
                  id="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl marginTop={4}>
                <FormLabel htmlFor="password">סיסמה</FormLabel>
                <Input
                  id="password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormControl>
              <Button
                mt={4}
                onClick={onSubmit}
                width={"100%"}
                disabled={!inputsValid}
                shadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
              >
                המשך
              </Button>
            </>
          )}
          <VStack width="100%" >
            <Button
              mt={4}
              width={"100%"}
              bg="white"
              colorScheme="white"
              shadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
              color="#00000046"
              rightIcon={<GoogleIcon height="24px" />}
              onClick={onGoogleLogin}
            >
              Continue with google
            </Button>
            <Button
              mt={4}
              width={"100%"}
              bg="black"
              colorScheme="black"
              shadow="rgba(0, 0, 0, 0.1) 0px 4px 12px"
              color="white"
              rightIcon={<AppleIcon height="24px" />}
              onClick={onAppleLogin}
            >
              Sign in with Apple
            </Button>
          </VStack>
          <Center marginTop="4px">
            <Text fontSize="small" color="text">
              התחברות לאפליקציה מהווה הסכמה ל
              <a href="https://info.tzav-rishon.co.il/%d7%aa%d7%a0%d7%90%d7%99-%d7%a9%d7%99%d7%9e%d7%95%d7%a9-%d7%95%d7%a4%d7%a8%d7%98%d7%99%d7%95%d7%aa-%d7%a6%d7%95-%d7%a8%d7%90%d7%a9%d7%95%d7%9f/">
                תנאי השימוש
              </a>
            </Text>
          </Center>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
