import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        background: "#F7FAFF",
      },
    },
  },
  fonts: {
    heading: `'VarelaRound', sans-serif`,
    body: `'Quicksand', sans-serif`,
  },
  components: {
    Text: {
      baseStyle: {
        color: "text",
      },
    },
    Button: {
      defaultProps: {
        colorScheme: "green",
      },
    },
    Input: {
      baseStyle: {
        field: {
          background: "white",
        },
      },
    },
  },
  colors: {
    text: "#718096",
    green: {
      50: "#F5F7EE",
      100: "#E4E8CF",
      200: "#D3D9B0",
      300: "#C2CA91",
      400: "#B1BB72",
      500: "#A0AC53",
      600: "#808943",
      700: "#606732",
      800: "#404521",
      900: "#202211",
    },
    cyan: {
      50: "#E9FBFB",
      100: "#C2F4F5",
      200: "#9AEDEF",
      300: "#73E6E8",
      400: "#4BDFE2",
      500: "#24D8DB",
      600: "#1DADAF",
      700: "#158284",
      800: "#0E5658",
      900: "#072B2C",
    },
    yellow: {
      50: "#FFF7E5",
      100: "#FFE7B8",
      200: "#FFD88A",
      300: "#FFC95C",
      400: "#FFBA2E",
      500: "#FFAA00",
      600: "#CC8800",
      700: "#996600",
      800: "#664400",
      900: "#332200",
    },
    red: {
      50: "#FCE9E9",
      100: "#F6C2C0",
      200: "#F19B98",
      300: "#EB7470",
      400: "#E54C48",
      500: "#E0251F",
      600: "#B31E19",
      700: "#861613",
      800: "#590F0D",
      900: "#2D0706",
    },
  },
});

export default theme;
