import { Button, Wrap } from "@chakra-ui/react";
import { useNavigate } from "react-router";

const ActionsGrid = ({ isUserPayed, onBuyApp, onActivateSerial }) => {
  const navigate = useNavigate();
  return (
    <Wrap>
      <Button colorScheme="gray" onClick={() => navigate(`/review/all`)}>
        צפה במבחנים שבוצעו
      </Button>
      <Button colorScheme="gray" onClick={() => navigate(`/grades`)}>
        ציונים
      </Button>

      {isUserPayed !== null && !isUserPayed && (
        <>
          <Button onClick={onActivateSerial} colorScheme="green">
            הפעל סריאל
          </Button>
          <Button onClick={onBuyApp} colorScheme="green">
            רכוש את הגרסה המלאה
          </Button>
        </>
      )}
    </Wrap>
  );
};

export default ActionsGrid;
