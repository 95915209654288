import { Box, Divider, Heading, Text, Wrap } from "@chakra-ui/react";
import Card from "../../../components/Card";
import {
  getFormattedTestLength,
  getFormattedTestQuestionAmount,
} from "../../../model/test";

const useTestCard = (test) => {
  return {
    name: test.name,
    questions: getFormattedTestQuestionAmount(test),
    duration: getFormattedTestLength(test),
  };
};

const TestCard = ({ test, onClick, disabled }) => {
  const { name, duration, questions } = useTestCard(test);

  return (
    <Card
      onClick={onClick}
      cursor="pointer"
      opacity={disabled ? 0.4 : 1}
      _hover={{ background: "whiteAlpha.300" }}
    >
      <Heading as="h4" color="blackAlpha.800" fontSize="x-large">
        {name}
      </Heading>
      <Divider color="yellow.400" />
      <Box height="16px" />
      <Text>אורך {duration}</Text>
      <Text>{questions}</Text>
    </Card>
  );
};

export default TestCard;
