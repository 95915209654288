import {
  Avatar,
  Button,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useMatch } from "react-router-dom";
import VerticalDivider from "./VerticalDivider";

const Profile = ({ user }) => {
  const auth = getAuth();
  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.reload();
    } catch (e) {
      console.error(`failed to sign out: ${e}`);
    }
  };
  return (
    <Menu>
      <MenuButton  as="button">
        <Avatar size="sm" name={user.displayName} src={user.photoURL} />
      </MenuButton>
      <MenuList background="white">
        <MenuItem onClick={handleLogout}>
          <Text>התנתק</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const Navbar = () => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const match = useMatch("/");
  return (
    <Flex
      height="48px"
      width="100vw"
      padding="12px 18px"
      as="nav"
      justify="space-between"
      align="center"
      zIndex="999"
      bg="green.400"
    >
      <Link to="/">
        <Heading size="md" color="white">
          יום המאה
        </Heading>
      </Link>

      {!loading && user !== null && (
        <HStack
          divider={
            <VerticalDivider height="32px" borderColor="whiteAlpha.600" />
          }
        >
          <HStack>
            {!match && (
              <Link to="/">
                <Button size="sm">מסך בית</Button>
              </Link>
            )}
            {/* <Link to="/review/all">
              <Button size="sm">הסתיימו</Button>
            </Link> */}
          </HStack>
          <HStack>
            <Profile user={user} />
          </HStack>
        </HStack>
      )}
    </Flex>
  );
};

export default Navbar;
