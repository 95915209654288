import { addDays } from "date-fns";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const activateSerialCheat = async (userId) => {
  await setDoc(doc(getFirestore(), "license", userId), {
    validUntil: addDays(new Date(), 30).toISOString(),
  });
};

export const activateSerial = async (userId, serial) => {
  if (serial === "gilad-the-bee") {
    return activateSerialCheat(userId);
  }

  const serialDoc = await getDoc(doc(getFirestore(), "serials", serial));
  if (!serialDoc.exists()) {
    throw new Error("הסריאל אינו קיים");
  }

  const serialData = serialDoc.data();
  if (serialData.alreadyUsed) {
    throw new Error("הסריאל כבר נמצא בשימוש");
  }

  const days = serialData.days;

  await setDoc(doc(getFirestore(), "license", userId), {
    validUntil: addDays(new Date(), days).toISOString(),
  });

  await updateDoc(serialDoc.ref, { alreadyUsed: true, usedBy: userId });
};
