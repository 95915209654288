import { useToast } from "@chakra-ui/react";
import { isBefore } from "date-fns";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useCurrentUser } from "../hooks/auth";
import { useEffect } from "react";

export const useIsUserPayed = () => {
  const toast = useToast();
  const user = useCurrentUser();
  if (user === null)
    throw new Error("user must not be null to get license data");

  const [license, loading, error] = useUserLicense(user.uid);

  useEffect(() => {
    if (error) {
      console.error(error);
      toast({
        title: "רישיון",
        description: "הייתה בעיה בטעינת הרישיון של המשתמש",
        status: "error",
      });
    }
  }, [error]);

  if (loading) return null;

  return isUserLicenseValid(license);
};

export const useUserLicense = (userId) => {
  return useDocumentData(doc(getFirestore(), "license", userId));
};

export const isUserLicenseValid = (license) => {
  if (license === undefined) return false;
  return isBefore(new Date(), new Date(license.validUntil));
};
