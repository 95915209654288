import {
  Button,
  Center,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import ErrorView from "../../components/ErrorView";
import KidodTestContent from "../../components/KidodTestContent";
import LoadingScreen from "../../components/LoadingScreen";
import TestContent from "../../components/TestContent";
import TestDescription from "../../components/TestDescription";
import VerticalDivider from "../../components/VerticalDivider";
import { useCurrentUser } from "../../hooks/auth";
import { calculateTestScore, saveTestResults } from "../../model/test";
import { useTestClock, useTestData } from "./hooks";

const useTest = (user, test) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [ended, setEnded] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(-1);

  const { testStarted, startTest, timeLeft, didTimeout, timeItTookInSeconds } =
    useTestClock(test);

  const currentQuestion = useMemo(() => {
    if (test === undefined) return null;
    return test.questions[questionIndex];
  }, [test, questionIndex]);

  const handleTestEnd = async () => {
    if (ended) return;
    setEnded(true);

    try {
      const [score, accuracy] = calculateTestScore(test, answers);
      const id = await saveTestResults(
        user.uid,
        test,
        timeItTookInSeconds,
        score,
        answers,
        { accuracy }
      );
      toast({
        title: "סיום מבחן",
        description: "המבחן הסתיים בהצלחה",
        status: "success",
        isClosable: true,
      });
      navigate(`/review/${id}`, { replace: true });
    } catch (error) {
      console.error(error);
      toast({
        title: "סיום מבחן",
        description: "הייתה בעיה בשמירת המבחן",
        status: "error",
        isClosable: true,
      });
      setEnded(false);
    }
  };

  useEffect(() => {
    if (didTimeout && !ended) handleTestEnd();
  }, [didTimeout, ended]);

  const handleAnswerSelect = (answer) => {
    if (ended) return;
    if (answer === selectedAnswer) setSelectedAnswer(-1);
    else setSelectedAnswer(answer);
  };

  const handleSubmit = () => {
    if (selectedAnswer === -1) {
      console.log(`an answer must be selected before submission`);
      return;
    }

    if (answers.length === test.questions.length) return;

    setAnswers([...answers, selectedAnswer]);
    setSelectedAnswer(-1);

    // increment the question index but limit it to
    // the questions length
    setQuestionIndex(Math.min(questionIndex + 1, test.questions.length - 1));
  };

  // end test if all questions were answered
  useEffect(() => {
    if (test?.questions?.length === answers.length) handleTestEnd();
  }, [answers, test]);

  return {
    testName: test?.name,
    testStarted,
    startTest,
    timeLeft,
    currentQuestion,
    questionIndex,
    handleAnswerSelect,
    selectedAnswer,
    handleSubmit,
  };
};

const Test = () => {
  const { id } = useParams();
  const user = useCurrentUser();
  const [test, loading, error] = useTestData(id);

  const [showDescription, setShowDescription] = useState(false);

  const {
    testName,
    testStarted,
    startTest,
    timeLeft,
    currentQuestion,
    handleAnswerSelect,
    selectedAnswer,
    handleSubmit,
    questionIndex,
  } = useTest(user, test);

  if (error) {
    return (
      <Container>
        <Center>
          <ErrorView error={error} />
        </Center>
      </Container>
    );
  }

  if (loading) {
    return <LoadingScreen />;
  }

  const testContent = () => {
    if (!testStarted || showDescription) {
      return (
        <TestDescription
          actionText={testStarted ? "חזור" : "התחל מבחן"}
          test={test}
          onStart={() => {
            if (testStarted) {
              setShowDescription(false);
            } else {
              startTest();
            }
          }}
        />
      );
    }

    if (test.type === "kidod") {
      return (
        <KidodTestContent
          test={test}
          selectedAnswer={selectedAnswer}
          handleAnswerSelect={handleAnswerSelect}
          handleSubmit={handleSubmit}
          currentQuestionIndex={questionIndex}
        />
      );
    }
    return (
      <TestContent
        information={currentQuestion.information}
        question={currentQuestion.question}
        options={currentQuestion.options}
        selectedAnswer={selectedAnswer}
        handleAnswerSelect={handleAnswerSelect}
        handleSubmit={handleSubmit}
      />
    );
  };

  return (
    <Container>
      <Row style={{ marginTop: "16px" }}>
        <Center>
          <VStack>
            <HStack>
              <Heading as="h3" fontSize="x-large">
                {testName}
              </Heading>
              {!showDescription && testStarted && (
                <Button
                  width="24px"
                  height="24px"
                  borderRadius="12px"
                  size="small"
                  onClick={() => setShowDescription(true)}
                >
                  ?
                </Button>
              )}
            </HStack>
            {testStarted && (
              <HStack>
                <Text>{timeLeft}</Text>
                <Text>|</Text>
                <Text>
                  שאלה: {questionIndex + 1}/{test.questions.length}
                </Text>
              </HStack>
            )}
          </VStack>
        </Center>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Center>{testContent()}</Center>
      </Row>
    </Container>
  );
};

export default Test;
