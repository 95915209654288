import { Button, Heading, Stack, Text, useToast } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { Container, Row } from "react-bootstrap";

import {
  reauthenticateWithPopup,
  reauthenticateWithRedirect,
} from "firebase/auth";

import { useIsDesktop } from "../../hooks/window";

import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

googleProvider.setDefaultLanguage("he");
appleProvider.setDefaultLanguage("he");

const reauthUser = async (auth, desktop) => {
  let provider;
  const [{ providerId }] = auth.currentUser.providerData;
  if (providerId === "google.com") {
    provider = googleProvider;
  } else {
    provider = appleProvider;
  }

  if (desktop) {
    return reauthenticateWithPopup(auth.currentUser, provider);
  }
  return reauthenticateWithRedirect(auth.currentUser, provider);
};

const deleteUser = async (auth, desktop) => {
    try {
        return auth.currentUser.delete();
    } catch(err) {
        debugger
        await reauthUser(auth, desktop);
    }
};

const Reset = () => {
  const auth = getAuth();
  const toast = useToast();
  const desktop = useIsDesktop();
  return (
    <Container>
      <Row
        style={{ display: "flex", justifyContent: "center", marginTop: "64px" }}
      >
        <Stack maxWidth="320px" width="80vw" textAlign="center">
          <Heading>מחיקת משתמש</Heading>
          <Text>פעולה זאת תמחק את המשתמש וכל המידע המקושר אליו</Text>
          <Button
            onClick={() => {
              deleteUser(auth, desktop)
                .then(() => {
                  toast({
                    title: "מחיקת משתמש",
                    description: "המשתמש נמחק בהצלחה",
                    status: "success",
                  });
                })
                .catch((err) => {
                  console.error(err);
                  toast({
                    title: "מחיקת משתמש",
                    description: "הייתה בעיה עם מחיקת המשתמש",
                    status: "error",
                  });
                });
            }}
          >
            מחק מידע
          </Button>
        </Stack>
      </Row>
    </Container>
  );
};

export default Reset;
