import {
  AspectRatio,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import Card from "./Card";
import { isURL } from "../util";
import { useIsMobile, useWindowSize } from "../hooks/window";
import { useRef } from "react";

const QuestionCard = ({ value, information, ...props }) => {
  const isMobile = useIsMobile();
  return (
    <SimpleGrid {...props} spacing="8px">
      {information && (
        <Card overflowY="scroll" maxHeight="50vh">
          <Center minHeight="inherit" color="inherit">
            <VStack>
              <Heading fontSize="medium" color="text">
                מידע
              </Heading>

              {isURL(information) ? (
                <Image
                  padding="4px"
                  maxWidth={isMobile ? "80vw" : "512px"}
                  src={information}
                  alt="question info"
                />
              ) : (
                <Text color="inherit">{information}</Text>
              )}
            </VStack>
          </Center>
        </Card>
      )}
      <Card>
        <VStack height="100%">
          <Heading fontSize="medium" color="text">
            שאלה
          </Heading>
          {isURL(value) ? (
            <Image
              maxHeight="312px"
              height="100%"
              src={value}
              alt="question image"
            />
          ) : (
            <>
              {value.split(":").map((v) => (
                <Text textAlign="center" fontSize="large" color="inherit">
                  {v}
                </Text>
              ))}
            </>
          )}
        </VStack>
      </Card>
    </SimpleGrid>
  );
};

const OptionCard = ({ value, onClick, ...props }) => {
  return (
    <Card onClick={onClick} {...props}>
      <Center minHeight="inherit" color="inherit">
        {isURL(value) ? (
          <Image src={value} alt="question image" />
        ) : (
          <Text textAlign="center" color="inherit">
            {value}
          </Text>
        )}
      </Center>
    </Card>
  );
};

const TestContent = ({
  question,
  options,
  selectedAnswer,
  handleAnswerSelect,
  handleSubmit,
  selectedMistake,
  information,
  explanation,
}) => {
  const ref = useRef();
  const { width } = useWindowSize();
  const minQuestionWidth = () => {
    if (width < 512) {
      return "100%";
    }
    return "512px";
  };

  const minOptionWidth = () => {
    if (!ref.current) return 0;
    return ref.current.clientWidth / 3;
  };

  return (
    <VStack ref={ref}>
      <QuestionCard
        value={question}
        information={information}
        minHeight="256px"
        minWidth={minQuestionWidth()}
        maxWidth="80vw"
      />
      <SimpleGrid width="100%" spacing="4px" minChildWidth={minOptionWidth()}>
        {options.map((option, i) => {
          const offsetI = i + 1;
          let bgColor = "white";
          if (offsetI === selectedAnswer) bgColor = "green.400";
          if (offsetI === selectedMistake) bgColor = "red.400";
          return (
            <OptionCard
              key={i}
              minHeight="64px"
              minWidth="64px"
              value={option}
              onClick={() => handleAnswerSelect(offsetI)}
              cursor="pointer"
              color={bgColor !== "white" ? "white" : "text"}
              background={bgColor}
              __hover={{
                background: bgColor === "white" ? "green.600" : bgColor,
              }}
            />
          );
        })}
      </SimpleGrid>
      {explanation && (
        <VStack>
          <Heading marginTop="8px" fontSize="medium" color="text">
            הסבר
          </Heading>
          <Text maxWidth="512px" textAlign="center">
            {explanation}
          </Text>
        </VStack>
      )}
      {handleSubmit && (
        <Button
          width="100%"
          disabled={selectedAnswer === -1}
          onClick={handleSubmit}
        >
          המשך
        </Button>
      )}
    </VStack>
  );
};

export default TestContent;
