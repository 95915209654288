import { Box, Divider, Heading, Text, Wrap } from "@chakra-ui/react";
import Card from "../../../components/Card";

const LearningResourceCard = ({ resource }) => {
  return (
    <Card
      onClick={() => window.open(resource.link, "_blank")}
      cursor="pointer"
      _hover={{ background: "whiteAlpha.300" }}
    >
      <Heading as="h4" color="blackAlpha.800" fontSize="x-large">
        {resource.name}
      </Heading>
      <Divider color="yellow.400" />
      <Box height="16px" />
      <Text>{resource.description}</Text>
    </Card>
  );
};

export default LearningResourceCard;
